<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
  >
    <template #header>
      Dodawanie zlecenia
    </template>
    <template #default>
      <div v-if="isBusy" class="w-full p-4 h-full text-center">
        <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
      </div>
      <div v-else class="flex flex-wrap">
        <div class="mb-4 w-1/2 md:w-1/3 px-1">
          <label>data zlecenia</label>
          <input :class="{ 'border-red-300': errors.order_date !== undefined }" v-model="form.order_date" type="date">
          <p v-if="errors.order_date !== undefined" class="text-red-500 text-sm">{{ errors.order_date }}</p>
        </div>
        <div class="mb-4 w-full md:w-2/3 px-1">
          <label>typ zlecenia</label>
          <select v-model="form.type_id" :class="{ 'border-red-300': errors.type_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
          </select>
          <p v-if="errors.type_id !== undefined" class="text-red-500 text-sm">{{ errors.type_id }}</p>
        </div>
        <div class="mb-4 w-full md:w-3/5 px-1">
          <label>zleceniodawca</label>
          <select v-model="form.contractor_id" :class="{ 'border-red-300': errors.contractor_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="contractor in contractors" :key="contractor.id" :value="contractor.id">{{ contractor.shortname }}</option>
          </select>
          <p v-if="errors.contractor_id !== undefined" class="text-red-500 text-sm">{{ errors.contractor_id }}</p>
        </div>
        <div class="mb-4 w-full md:w-2/5 px-1">
          <label>nr szkody w TU</label>
          <input :class="{ 'border-red-300': errors.claim_no !== undefined }" v-model="form.claim_no" type="text">
          <p v-if="errors.claim_no !== undefined" class="text-red-500 text-sm">{{ errors.claim_no }}</p>
        </div>
        <div class="w-full">
          <span class="font-semibold">poszkodowany</span>
          <hr>
        </div>
        <div class="flex justify-start my-1 w-full px-1">
          <label class="inline-block">
            <input v-model="form.is_company" true-value="1" false-value="0" class="w-auto" type="checkbox"> firma
          </label>
        </div>
        <template v-if="form.is_company === '0'">
          <div class="mb-4 w-1/3 px-1">
            <label>imię</label>
            <Autocomplete
              resource="forenames"
              :inputClass="errors['person.first_name'] !== undefined ? 'border-red-300' : ''"
              v-model="form.person.first_name"
            />
            <p v-if="errors['person.first_name'] !== undefined" class="text-red-500 text-sm">{{ errors['person.first_name'] }}</p>
          </div>
          <div class="mb-4 w-2/3 px-1">
            <label>nazwisko</label>
            <input :class="{ 'border-red-300': errors['person.surname'] !== undefined }" v-model="form.person.surname" type="text">
            <p v-if="errors['person.surname'] !== undefined" class="text-red-500 text-sm">{{ errors['person.surname'] }}</p>
          </div>
        </template>
        <template v-else>
          <div class="mb-4 w-full px-1">
            <label>nazwa firmy</label>
            <input :class="{ 'border-red-300': errors['company.name'] !== undefined }" v-model="form.company.name" type="text">
            <p v-if="errors['company.name'] !== undefined" class="text-red-500 text-sm">{{ errors['company.name'] }}</p>
          </div>
        </template>
        <div class="w-full">
          <span class="font-semibold">dane kontaktowe</span>
          <hr>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>telefon</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.phone" type="text">
          <p v-if="errors.phone !== undefined" class="text-red-500 text-sm">{{ errors.phone }}</p>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label>email</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.email" type="email">
          <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
        <div class="w-full">
          <span class="font-semibold">miejsce oględzin</span>
          <hr>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label>ulica</label>
          <input :class="{ 'border-red-300': errors.street !== undefined }" v-model="form.street" type="text">
          <p v-if="errors.street !== undefined" class="text-red-500 text-sm">{{ errors.street }}</p>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>nr domu/mieszkania</label>
          <input :class="{ 'border-red-300': errors.house_apt_no !== undefined }" v-model="form.house_apt_no" type="text">
          <p v-if="errors.house_apt_no !== undefined" class="text-red-500 text-sm">{{ errors.house_apt_no }}</p>
        </div>
        <div class="mb-4 w-1/3 md:w-1/6 px-1">
          <label>kod poczt.</label>
          <Autocomplete
            resource="postal-codes"
            :inputClass="errors.postal_code !== undefined ? 'border-red-300' : ''"
            v-model="form.postal_code"
            @selected="setCity"
          />
          <p v-if="errors.postal_code !== undefined" class="text-red-500 text-sm">{{ errors.postal_code }}</p>
        </div>
        <div class="mb-4 w-2/3 md:w-1/2 px-1">
          <label>miasto</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors.city !== undefined ? 'border-red-300' : ''"
            v-model="form.city"
          />
          <p v-if="errors.city !== undefined" class="text-red-500 text-sm">{{ errors.city }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/3 px-1">
          <label>region</label>
          <select v-model="form.region_id" :class="{ 'border-red-300': errors.region_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
          </select>
          <p v-if="errors.region_id !== undefined" class="text-red-500 text-sm">{{ errors.region_id }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import OrderService from '../services/OrderService';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'OrderAdd',
  mixins: [errorHandler],
  components: { Autocomplete },
  data() {
    return {
      form: {
        order_date: moment().format('YYYY-MM-DD'),
        type_id: '',
        status_id: '1',
        contractor_id: '',
        claim_no: '',
        is_company: '0',
        person: {
          surname: '',
          first_name: '',
        },
        company: {
          name: '',
        },
        phone: '',
        email: '',
        street: '',
        house_apt_no: '',
        postal_code: '',
        city: '',
        region_id: '',
      },
      errors: {},
      show: false,
      isBusy: false,
    };
  },
  computed: {
    ...mapState('orders', [
      'statuses',
      'types',
      'contractors',
    ]),
    ...mapState('regions', ['regions']),
  },
  mounted() {
    this.$store.dispatch('orders/getStatuses');
    if (this.types.length === 0) {
      this.$store.dispatch('orders/getTypes');
    }
    this.$store.dispatch('orders/getContractors');
    if (this.regions.length === 0) {
      this.$store.dispatch('regions/get');
    }
    this.show = true;
  },
  methods: {
    save() {
      this.isBusy = true;
      OrderService.addOrder(this.form)
        .then(response => {
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zlecenie zostało dodane',
          });
          this.isBusy = false;
          this.show = false;
          this.$router.push({ name: 'order', params: { id: response.data.result.id } });
        })
        .catch(error => {
          this.isBusy = false;
          this.errors = this.resolveError(error);
        });
    },
    setCity(payload) {
      this.form.city = payload.city;
    },
  },
  watch: {
    is_company(val) {
      if (val === '1') {
        Object.keys(this.person).forEach(item => {
          this.person[item] = '';
        });
      } else {
        Object.keys(this.company).forEach(item => {
          this.company[item] = '';
        });
      }
    },
  },
};
</script>

<style>

</style>
