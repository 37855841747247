<template>
  <div>
    <div class="flex flex-col lg:flex-row flex-wrap gap-2 items-start xl:items-center">
      <div v-if="allowAddOrder" class="w-full xl:w-auto">
        <button @click="showOrderAdd = true" class="py-2 px-3 bg-blue-400 hover:bg-blue-600 text-blue-100 hover:text-white rounded-sm" title="dodaj zlecenie">
          <font-awesome-icon icon="plus" /> <font-awesome-icon icon="file-contract" />
        </button>
      </div>
      <div>
        <!-- Button with addons -->
        <div class="w-full xl:w-auto flex flex-none items-center border border-gray-500 shadow rounded-sm" :class="{'bg-yellow-200': search !== ''}">
          <input
            id="filterInput"
            class="appearance-none bg-transparent border-none w-full py-1 px-2 leading-tight focus:outline-none"
            v-model="search"
            type="text"
            placeholder="Wpisz aby wyszukać..."
            @keyup.enter="filterGo"
            @keyup.esc="resetFilters"
          >
          <button
            class="flex-shrink-0 border-transparent border-4 text-gray-600 hover:text-gray-900 text-sm py-1 px-2 rounded"
            type="button"
            :disabled="search === ''" @click="resetFilters"
            title="wyczyść"
          ><font-awesome-icon icon="times"/></button>
          <button
            class="flex-shrink-0 bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600 text-sm border-4 text-white py-1 px-2"
            type="button"
            :disabled="search === ''"
            @click="filterGo"
          >
            Szukaj
          </button>
        </div>

      </div>
      <div class="flex flex-col lg:flex-row w-full xl:w-auto gap-2 xl:items-center">
        <div v-for="(searchField, index) in allowedSearchFields" :key="index" class="flex items-center">
          <label class="font-semibold">
            <input class="w-auto leading-tight" v-model="searchField.active" type="checkbox">
            <span>
              {{ searchField.label }}
            </span>
          </label>
        </div>
        <button
          @click="setSearchAll"
          class="text-gray-600 bg-transparent border border-gray-600 text-sm font-semibold rounded-sm py-1 px-2 focus:shadow-outline"
          :class="{'hover:bg-gray-500 hover:text-gray-100 shadow': !searchAll}"
          :disabled="searchAll"
        >
          {{ searchAll ? 'wszystkie' : 'wybrane' }}
        </button>
      </div>
      <div class="flex items-center">
        <div class="flex items-center">
          <label class="font-semibold">
            <input class="w-auto sm:w-auto leading-tight" @change="setFilter" v-model="hide_archived" true-value='1' false-value='0' type="checkbox">
            <span>
              ukryj zarchiwizowane
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="inline-block relative w-64 mt-4 mr-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow" :class="{'bg-yellow-200': status !== null}">
          <select
            @change="setFilter"
            v-model="status"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option :value="null">Status (wszystkie)</option>
            <option
              class="appearance-none bg-gray-100"
              v-for="status in orderStatuses"
              :key="status.id"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="inline-block relative w-64 mt-4 mr-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow" :class="{'bg-yellow-200': type !== null}">
          <select
            @change="setFilter"
            v-model="type"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option :value="null">Typ zl. (wszystkie)</option>
            <option
              class="appearance-none bg-gray-100"
              v-for="type in orderTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div v-show="userAbilities.includes('orders_filter_contractors')" class="inline-block relative w-64 mt-4 mr-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow" :class="{'bg-yellow-200': contractor !== null}">
          <select
            @change="setFilter"
            v-model="contractor"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option class="appearance-none bg-gray-100" :value="null">TU (wszystkie)</option>
            <option
              class="appearance-none bg-gray-100"
              v-for="contractor in contractors"
              :key="contractor.id"
              :value="contractor.id"
            >
              {{ contractor.shortname }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="relative mt-4 mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm" :class="{'bg-yellow-200': orderDateFrom !== ''}">
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
          title="data zlecenia od:"
        >
          Data zl. od
        </button>
        <input
          @change="setFilter"
          v-model="orderDateFrom"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-3 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          title="data zlecenia od:"
        />
      </div>
      <div class="relative mt-4 mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm" :class="{'bg-yellow-200': orderDateTo !== ''}">
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
          title="data zlecenia do:"
        >
          Data zl. do
        </button>
        <input
          @change="setFilter"
          v-model="orderDateTo"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-3 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          title="data zlecenia do:"
        />
      </div>
      <div class="relative mt-4 mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm" :class="{'bg-yellow-200': orderPostalCode !== ''}">
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
          title="kod pocztowy oględzin"
        >
          kod poczt.
        </button>
        <input
          @keyup.enter="setPostalCode(orderPostalCodeTmp)"
          @keyup.esc="setPostalCode('')"
          @blur="orderPostalCodeTmp = orderPostalCode"
          v-model="orderPostalCodeTmp"
          type="text"
          maxlength="6"
          class="appearance-none w-32 text-gray-700 bg-transparent px-3 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          title="ENTER - zatwierdź, ESC - wyczyść"
        />
      </div>
      <div class="inline-block relative w-64 mt-4">
        <div class="flex items-stretch box-border border border-gray-500 hover:border-gray-700 rounded-sm shadow">
          <button
            class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white py-1 px-2 cursor-default"
            type="button"
            title="Pole i kierunek sortowania"
          >
            <svg class="fill-current text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/></svg>
          </button>
          <select
            @change="selectSort"
            v-model="sortSelected"
            class="block appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option
              class="appearance-none bg-gray-100"
              v-for="(option, index) in sortOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 border-gray-300">

    <Pagination
      :current="pagData.meta.current_page"
      :total="pagData.meta.last_page"
      v-model="pagData.perPage"
      @perPageChanged="perPageChanged"
      :linkGen="linkGen"
    />

    <div class="list-group">
    <!-- <div class="block"> -->
      <div class="hidden xl:flex w-full py-1 px-5 border-b border-gray-400 font-semibold">
        <div class="column-wrapper-1">
          <div class="table-column-1 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[0].name},${activeSortObj.field === columns[0].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
          >
            <span class="font-bold">{{ columns[0].label }}</span>
            <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[0].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path v-if="activeSortObj.field === columns[0].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
              <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            </svg>
          </div>
          <div class="table-column-2 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[1].name},${activeSortObj.field === columns[1].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
          >
            <span class="font-bold">{{ columns[1].label }}</span>
            <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[1].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path v-if="activeSortObj.field === columns[1].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
              <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            </svg>
          </div>
        </div>
        <div class="column-wrapper-2">
          <div class="table-column-3 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[2].name},${activeSortObj.field === columns[2].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
          >
            <span class="font-bold">{{ columns[2].label }}</span>
            <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[2].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path v-if="activeSortObj.field === columns[2].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
              <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            </svg>
          </div>
          <div class="table-column-4 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[3].name},${activeSortObj.field === columns[3].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
          >
            <span class="font-bold">{{ columns[3].label }}</span>
            <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[3].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path v-if="activeSortObj.field === columns[3].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
              <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            </svg>
          </div>
        </div>
        <div class="table-column-5 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[4].name},${activeSortObj.field === columns[4].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
        >
          <span class="font-bold">{{ columns[4].label }}</span>
          <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[4].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path v-if="activeSortObj.field === columns[4].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
          </svg>
        </div>
        <div class="table-column-6 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[5].name},${activeSortObj.field === columns[5].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
        >
          <span class="font-bold">{{ columns[5].label }}</span>
          <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[5].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path v-if="activeSortObj.field === columns[5].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
          </svg>
        </div>
        <div class="table-column-7 flex gap-1 cursor-pointer"
            @click="sortSelected = `${columns[6].name},${activeSortObj.field === columns[6].name && activeSortObj.dir === 'asc' ? 'desc' : 'asc'}`"
        >
          <span class="font-bold">{{ columns[6].label }}</span>
          <svg class="fill-current h-4 w-4 self-center" :class="activeSortObj.field === columns[6].name ? 'text-blue-500': 'text-gray-900 opacity-25'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path v-if="activeSortObj.field === columns[6].name && activeSortObj.dir === 'desc'" d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 14h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
            <path v-else d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
          </svg>
        </div>
      </div>
      <div v-if="isBusy">
        <div class="flex w-full py-3 px-5">
          <div class="flex items-center text-center w-full">
            <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
            Ładuję...
          </div>
        </div>
      </div>
      <template v-else>
        <div v-for="(order, index) in orders" :key="order.id" @click="$router.push({ name: 'order', params: { id: order.id }})"
          class="orders-table-row"
        >
          <div class="column-wrapper-1">
            <div class="table-column-1">
              <span class="xl:hidden"><strong>nr zlecenia: </strong></span>{{ order.signature }}
            </div>
            <div class="table-column-2">
              <span class="xl:hidden"><strong>data zl.: </strong></span>{{ order.order_date }}
            </div>
          </div>
          <div class="column-wrapper-2 table-column_possible_overflow">
            <div class="table-column-3 table-column_possible_overflow">
              <span class="xl:hidden"><strong>nr szkody: </strong></span>{{ order.claim_no }}
            </div>
            <div class="table-column-4 table-column_possible_overflow">
              <span class="xl:hidden"><strong>zleceniodawca: </strong></span>{{ order.contractor.company.name }}
            </div>
          </div>
          <div class="table-column-5 table-column_possible_overflow">
            <a @click.stop :href="googleMapUrl(index)" target="_blank">
              <font-awesome-icon icon="map-marked-alt" />&nbsp;
            </a>
            <div>
              {{ order.postal_code }}, {{ order.city }}
            </div>
          </div>
          <div class="table-column-6">
            <span class="xl:hidden"><strong>typ: </strong></span>{{ order.type.name }}
          </div>
          <div class="table-column-7">
            <span class="xl:hidden"><strong>status: </strong></span>{{ order.status.name }}
          </div>
        </div>
      </template>
    </div>
    <OrderAdd
      v-if="showOrderAdd"
      @close="showOrderAdd = false"
    ></OrderAdd>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderService from '../services/OrderService';
import Pagination from '../components/Pagination.vue';
import OrderAdd from '../components/OrderAdd.vue';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'Orders',
  components: {
    Pagination,
    OrderAdd,
  },
  mixins: [errorHandler],
  data() {
    return {
      query: {},
      queryDefaults: {
        page: 1,
        perPage: 20,
        order: 'order_date,desc',
        hide_archived: '1',
      },
      isBusy: true,
      orders: [],
      search: '',
      hide_archived: '1',
      status: null,
      contractor: null,
      type: null,
      orderDateFrom: '',
      orderDateTo: '',
      orderPostalCode: '',
      orderPostalCodeTmp: '',
      searchFields: [
        { value: 'or_signature', label: 'nr zlecenia', active: false, allow: 'all' },
        { value: 'or_claim_no', label: 'nr szkody', active: false, allow: 'all' },
        { value: 'or_subject_name', label: 'poszkodowany', active: false, allow: 'all' },
        { value: 'or_supervisor_name', label: 'nadzór', active: false, allow: 'orders_filter_employees' },
        { value: 'or_expert_name', label: 'ekspert', active: false, allow: 'orders_filter_employees' },
      ],
      pagData: {
        links: {},
        meta: {},
        perPage: 10,
      },
      sortSelected: '',
      sortOptions: [
        { value: 'signature,asc', text: 'nr zlecenia rosnąco' },
        { value: 'signature,desc', text: 'nr zlecenia malejąco z-a' },
        { value: 'order_date,asc', text: 'data zl. rosnąco' },
        { value: 'order_date,desc', text: 'data zl. malejąco' },
        { value: 'claim_no,asc', text: 'nr szkody rosnąco' },
        { value: 'claim_no,desc', text: 'nr szkody malejąco' },
        { value: 'contractor_name,asc', text: 'zleceniodawca rosnąco' },
        { value: 'contractor_name,desc', text: 'zleceniodawca malejąco' },
        { value: 'postal_code,asc', text: 'kod poczt. rosnąco' },
        { value: 'postal_code,desc', text: 'kod poczt. malejąco' },
        { value: 'type_name,asc', text: 'typ zl. rosnąco' },
        { value: 'type_name,desc', text: 'typ zl. malejąco' },
        { value: 'status_name,asc', text: 'status rosnąco' },
        { value: 'status_name,desc', text: 'status malejąco' },
      ],
      columns: [
        { name: 'signature', label: 'nr zlecenia', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-32' },
        { name: 'order_date', label: 'data zl', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-32' },
        { name: 'claim_no', label: 'nr szkody', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-64' },
        { name: 'contractor_name', label: 'zleceniodawca', sortable: true, href: '', classes: 'w-full sm:w-1/2 lg:w-auto lg:flex-grow' },
        { name: 'postal_code', label: 'kod poczt.', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-64' },
        { name: 'type_name', label: 'typ zlecenia', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-48' },
        { name: 'status_name', label: 'status', sortable: true, href: '', classes: 'flex-shrink-0 w-full sm:w-1/2 lg:w-56' },
      ],
      showOrderAdd: false,
    };
  },
  computed: {
    ...mapState('orders', {
      orderStatuses: state => state.statuses,
      orderTypes: state => state.types,
      contractors: state => state.contractors,
    }),
    userAbilities() {
      return this.$store.state.user.abilities;
    },
    canEverything() {
      return this.userAbilities.includes('can_everything');
    },
    allowAddOrder() {
      return this.canEverything || this.userAbilities.includes('create_order_all_contractors') || this.userAbilities.includes('create_order_contractor');
    },
    searchAll() {
      return this.activeSearchFields.length === 0 || this.activeSearchFields.length === this.allowedSearchFields.length;
    },
    activeSortObj() {
      const sort = this.$route.query.order !== undefined ? this.$route.query.order.split(',') : this.queryDefaults.order.split(',');
      return {
        field: sort[0],
        dir: sort[1],
      };
    },
    activeSearchFields() {
      return this.allowedSearchFields.filter(obj => obj.active).map(field => field.value);
    },
    allowedSearchFields() {
      return this.searchFields.filter(field => this.userAbilities.includes(field.allow) || field.allow === 'all');
    },
  },
  mounted() {
    this.setQueryFromUri();
    this.$store.dispatch('orders/getStatuses');
    if (this.orderTypes.length === 0) {
      this.$store.dispatch('orders/getTypes');
    }
    this.$store.dispatch('orders/getContractors');
  },
  methods: {
    setQueryFromUri() {
      let changed = 0;
      if (Number.isNaN(Number.parseInt(this.$route.query.page, 10))) {
        this.query.page = 1;
        changed += 1;
      } else {
        this.query.page = Number.parseInt(this.$route.query.page, 10);
      }
      if (Number.isNaN(Number.parseInt(this.$route.query.perpage, 10))) {
        this.query.perpage = this.pagData.perPage;
        changed += 1;
      } else {
        this.query.perpage = Number.parseInt(this.$route.query.perpage, 10);
      }

      if (this.$route.query.order === undefined) {
        this.query.order = this.queryDefaults.order;
        changed += 1;
      } else {
        this.query.order = this.$route.query.order;
      }
      this.sortSelected = this.query.order;

      if (this.$route.query.hide_archived === undefined) {
        this.query.hide_archived = this.queryDefaults.hide_archived;
        changed += 1;
      } else {
        this.query.hide_archived = this.$route.query.hide_archived;
      }
      this.hide_archived = this.query.hide_archived;

      if (this.$route.query.search !== undefined && this.$route.query.search !== '') {
        this.query.search = this.$route.query.search;
        this.search = decodeURI(this.$route.query.search);
        this.allowedSearchFields.forEach(searchField => {
          if (this.$route.query[searchField.value] !== undefined) {
            this.query[searchField.value] = encodeURI(this.$route.query[searchField.value]);
            // eslint-disable-next-line no-param-reassign
            searchField.active = true;
            // this.activeSearchFields.push(searchField.value);
          }
        });
      }

      if (this.$route.query.contractor !== undefined) {
        this.query.contractor = this.$route.query.contractor;
        this.contractor = this.$route.query.contractor;
      }
      if (this.$route.query.status !== undefined) {
        this.query.status = this.$route.query.status;
        this.status = this.$route.query.status;
      }
      if (this.$route.query.type !== undefined) {
        this.query.type = this.$route.query.type;
        this.type = this.$route.query.type;
      }
      if (this.$route.query.orderDateFrom !== undefined) {
        this.query.orderDateFrom = this.$route.query.orderDateFrom;
        this.orderDateFrom = this.$route.query.orderDateFrom;
      }
      if (this.$route.query.orderDateTo !== undefined) {
        this.query.orderDateTo = this.$route.query.orderDateTo;
        this.orderDateTo = this.$route.query.orderDateTo;
      }
      if (this.$route.query.orderPostalCode !== undefined) {
        this.query.orderPostalCode = this.$route.query.orderPostalCode;
        this.orderPostalCode = this.$route.query.orderPostalCode;
        this.orderPostalCodeTmp = this.orderPostalCode;
      }

      if (changed > 0) {
        const { name, params } = this.$route;
        this.$router.replace({ name, params, query: this.query });
      } else {
        this.getOrders();
      }
    },
    getOrders() {
      this.isBusy = true;
      const query = {};
      Object.keys(this.$route.query).forEach(key => { query[key] = decodeURI(this.$route.query[key]); });
      OrderService.getOrders(query)
        .then(response => {
          this.isBusy = false;
          this.pagData.links = response.data.links;
          this.pagData.meta = response.data.meta;
          this.pagData.perPage = Number.parseInt(response.data.meta.per_page, 10);
          this.orders = response.data.data.result;
        })
        .catch(error => {
          this.isBusy = false;
          this.resolveError(error); // method from mixin
        });
    },
    linkGen(pageNum) {
      const query = {};
      Object.assign(query, this.$route.query);
      query.page = pageNum;
      return {
        name: 'orders',
        query,
      };
    },
    selectSort() {
      const routeName = this.$route.name;
      Object.assign(this.query, this.$route.query);
      if (this.query.order === this.sortSelected) {
        return false;
      }
      this.query.order = this.sortSelected;
      this.$router.push({ name: routeName, query: this.query });
      return true;
    },
    perPageChanged() {
      const routeName = this.$route.name;
      Object.assign(this.query, this.$route.query);
      this.query.perpage = this.pagData.perPage;
      this.query.page = 1;
      this.$router.push({ name: routeName, query: this.query });
    },
    resetFilters() {
      this.search = '';
      this.filterGo();
    },
    setSearchAll() {
      // this.activeSearchFields = [];
      // eslint-disable-next-line no-param-reassign
      this.allowedSearchFields.forEach(obj => { obj.active = false; });
    },
    setPostalCode(code) {
      if (code !== this.orderPostalCode) {
        this.orderPostalCode = code;
        this.setFilter();
      }
      if (code === '') {
        this.orderPostalCodeTmp = '';
      }
    },
    setFilter() {
      Object.assign(this.query, this.$route.query);
      this.query.page = 1;
      this.query.hide_archived = this.hide_archived;
      delete this.query.status;
      delete this.query.type;
      delete this.query.contractor;
      delete this.query.orderDateFrom;
      delete this.query.orderDateTo;
      delete this.query.orderPostalCode;
      if (this.status !== null) {
        this.query.status = this.status;
      }
      if (this.type !== null) {
        this.query.type = this.type;
      }
      if (this.contractor !== null) {
        this.query.contractor = this.contractor;
      }
      if (this.orderDateFrom !== '') {
        this.query.orderDateFrom = this.orderDateFrom;
      }
      if (this.orderDateTo !== '') {
        this.query.orderDateTo = this.orderDateTo;
      }
      if (this.orderPostalCode !== '') {
        this.query.orderPostalCode = this.orderPostalCode;
      }
      this.$router.push({ name: 'orders', query: this.query });
    },
    filterGo() {
      Object.assign(this.query, this.$route.query);
      delete this.query.search;
      this.allowedSearchFields.forEach(obj => {
        delete this.query[obj.value];
      });
      this.query.page = 1;
      if (this.search !== '') {
        this.query.search = encodeURI(this.search);
        this.activeSearchFields.forEach(field => {
          this.query[field] = 1;
        });
      }
      this.$router.push({ name: 'orders', query: this.query });
    },
    googleMapUrl(index) {
      const order = this.orders[index];
      let url = 'https://maps.google.com/?q=';
      if (order.street) {
        url = `${url}${order.street}`;
      }
      if (order.postal_code) {
        url = `${url}+${order.postal_code}`;
      }
      if (order.city) {
        url = `${url}+${order.city}`;
      }
      return url;
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
        this.getOrders();
      }
    },
    sortSelected: 'selectSort',
    'pagData.perPage': 'perPageChanged',
  },
};
</script>

<style scoped>
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
  .orders-table-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    padding: 0.75rem 1.25rem;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    @apply border-b;
    @apply border-gray-400;
  }
  @media only screen and (max-width: 1279px) {
    .orders-table-row {
      flex-wrap: wrap;
    }
  }
  .orders-table-row:last-child {
    @apply border-b-0;
  }
  .column-wrapper-1 {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1599px) {
    .column-wrapper-1 {
      flex-direction: column;
    }
  }
  .column-wrapper-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 0;
    min-width: 0;
  }
  @media only screen and (max-width: 1499px) {
    .column-wrapper-2 {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .table-column_const-width {
    flex-shrink: 0;
  }
  .table-column_possible_overflow {
    position: relative;
    overflow-x: hidden;
    padding-right: 1rem;
  }
  .table-column_possible_overflow:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 1rem;
    opacity: .5;
    background: white;
  }
  .table-column-1 {
    width: 8rem;
  }
  .table-column-2 {
    width: 6rem;
  }
  .table-column-3 {
    width: 16rem;
  }
  .table-column-4 {
    flex: 1 0 0;
    min-width: 0;
    white-space: nowrap;
  }
  .table-column-5 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: .5rem;
    width: 16rem;
  }
  .table-column-6 {
    width: 12rem;
    align-self: center;
  }
  .table-column-7 {
    width: 14rem;
    align-self: center;
  }
  @media only screen and (max-width: 1279px) {
    .column-wrapper-1, .column-wrapper-2 {
      flex: 0 0 auto;
      flex-direction: row;
      width: 100%;
    }
    .column-wrapper-2 {
      padding: 0;
    }
    .table-column-1,
    .table-column-2,
    .table-column-3,
    .table-column-4,
    .table-column-6,
    .table-column-7 {
      width: 50%;
    }
    .table-column-5 {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .column-wrapper-1,
    .column-wrapper-2 {
      flex-direction: column;
    }
    .table-column-1,
    .table-column-2,
    .table-column-3,
    .table-column-4,
    .table-column-5,
    .table-column-6,
    .table-column-7,
    .column-wrapper-1,
    .column-wrapper-2 {
      width: 100%;
    }
    .table-column_possible_overflow {
      overflow-x: visible;
    }
    .table-column_possible_overflow:after {
      display: none;
    }
  }

</style>
